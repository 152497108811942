<template>
    <!--
    The camera element is not currently in use due to several reasons.

    The GetMedia function will not always provide a stream from the best camera on a device in the case that
    multiple cameras are available. It will merely provide the first camera it can find that's not in use. When
    a specific camera is requested via ID, it's possible for the returned feed to be a black screen when the
    camera is being used by another program. There is no convenient way to tell if a camera is in use by another
    program.

    Furthermore, the GetMedia function will not take advantage of modern camera techniques, such as Dual-View
    technology where 2 or more cameras are both used simultaneously to improve picture quality.

    Until a solution can be found, pictures can instead be made using file upload. In this case, the camera app
    of a device can be selected to upload a picture, instead of using the device's file system to upload images.
-->
    <camera v-if="showCamera" @stop-camera="showCamera = false" @capture-photo="onCapturePhoto($event)"></camera>
    <default-layout id="create-job-component" v-else>
        <h1 class="text-center mt-3 mb-0">{{ capitalize(type) }}</h1>
        <h2 class="text-center text-info fw-bold">{{ activeUnit.id }}</h2>
        <el-form :model="formData" :rules="formRules" ref="formRef">
            <section class="my-4 mx-2">
                <el-form-item label="Description" prop="description">
                    <el-input
                        type="textarea"
                        placeholder="Description"
                        :rows="3"
                        maxlength="255"
                        show-word-limit
                        v-model="formData.description">
                    </el-input>
                </el-form-item>
            </section>
            <section v-if="isInventoryTask">
                <div class="my-4 px-2">
                    <el-form-item label="Inventory Type" prop="inventoryType">
                        <el-radio-group v-model="formData.inventoryType">
                            <el-radio-button label="replacement">Replacement</el-radio-button>
                            <el-radio-button label="missing">Missing</el-radio-button>
                            <el-radio-button label="other">Other</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <section class="my-4 mx-2" v-if="isProductTask">
                    <el-form-item label="Category" prop="category">
                        <el-select class="w-100"
                                   v-model="formData.category"
                                   placeholder="Select"
                                   @change="clearSelectedProduct()">
                            <el-option
                                v-for="category in productCategories"
                                :key="category"
                                :label="capitalize(category)"
                                :value="category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Product" prop="productId">
                        <el-select class="w-100"
                                   v-model="formData.productId"
                                   filterable
                                   placeholder="Search">
                            <el-option
                                class="text-wrap"
                                v-for="product in productsBelongingToSelectedCategory"
                                :key="product.id"
                                :label="capitalize(product.name)"
                                :value="product.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <section class="mt-2" v-if="selectedProduct">
                        <div class="py-2 d-flex align-items-baseline bb-product">
                            <div class="w-100 d-flex justify-content-between align-items-center">
                                <button type="button"
                                        class="p-0 ms-1 btn btn-clear text-info-dark text-start"
                                        @click="decreaseProductAmount(selectedProduct)"
                                        :disabled="!selectedProduct">
                                    <i class="me-2 fa fa-minus" />
                                </button>
                                <div class="text-center">
                                    <span class="text-info fw-bold font-large-1">{{ selectedProduct.amount }}</span>
                                    <span>&nbsp;&nbsp;</span>
                                    <span>{{ selectedProduct.data.name }}</span>
                                </div>
                                <button type="button"
                                        class="p-0 me-1 btn btn-clear text-info-dark text-start"
                                        @click="increaseProductAmount(selectedProduct)"
                                        :disabled="!selectedProduct">
                                    <i class="me-2 fa fa-plus" />
                                </button>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
            <section class="my-4 px-2" v-if="displayCameraBox">
                <div class="camera position-relative">
                    <template v-if="photoUrls.length > 0">
                        <div class="position-absolute w-100 h-100 d-flex flex-column justify-content-center">
                            <div class="d-flex justify-content-center">
                                <button type="button"
                                        class="mx-2 btn btn-secondary rounded-circle camera-btn-light elevate"
                                        @click="openFileDialog()">
                                    <i class="fa fa-camera" />
                                </button>
                                <button type="button"
                                        class="mx-2 btn btn-secondary rounded-circle camera-btn-light elevate"
                                        @click="deleteCurrentPhoto()">
                                    <i class="fa fa-trash" />
                                </button>
                            </div>
                        </div>
                        <div class="w-100 h-100" :class="{'hide-arrows': photoUrls.length <= 1}">
                            <el-carousel :autoplay="false" arrow="always" trigger="click" ref="jobPhotoCarousel">
                                <el-carousel-item v-for="(photoUrl, i) in photoUrls"
                                                  :key="i"
                                                  :name="i">
                                    <img alt="photo"
                                         :src="photoUrl"
                                         class="position-absolute p-1 w-100 h-100 fit-cover rounded z-index-n1">
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </template>
                    <div class="d-flex h-100 justify-content-center align-items-center" v-else>
                        <button type="button"
                                class="mx-2 btn btn-secondary rounded-circle camera-btn bg-danger"
                                @click="showCamera = true"
                                v-if="false">
                            <i class="fa fa-camera" />
                        </button>
                        <button type="button"
                                class="mx-2 btn btn-secondary rounded-circle camera-btn"
                                @click="openFileDialog()">
                            <i class="fa fa-camera" />
                        </button>
                    </div>
                </div>
                <input id="camera-image-upload"
                       alt="camera-image-upload"
                       type="file"
                       accept="image/png, image/jpeg"
                       @change="onFileUpload($event)"
                       hidden />
            </section>
            <section class="px-2" v-if="isHandymanTask">
                <el-checkbox class="my-2 d-block" v-model="materialsAreAvailable">Materials are available</el-checkbox>
                <el-checkbox class="my-2 d-block" v-model="requiresEmptyRoom">Room must be empty</el-checkbox>
                <el-checkbox class="my-2 d-block" v-model="blockRoom" :disabled="isBlocked">Block room</el-checkbox>
                <div v-if="blockRoom">
                    <el-date-picker
                        class="mb-1 me-1"
                        v-model="blockRoomFrom"
                        type="date"
                        placeholder="From"
                        format="DD-MM-YYYY"
                        :disabled-date="disabledDate"
                        disabled>
                    </el-date-picker>
                    <el-date-picker
                        v-model="blockRoomUntil"
                        type="date"
                        placeholder="Until"
                        format="DD-MM-YYYY"
                        :disabled-date="disabledDate">
                    </el-date-picker>
                </div>
            </section>
            <section class="my-4 px-2" v-if="!isEmpty(contractsByStatus)">
                <template v-if="isProductTask">
                    <div>Charge guests</div>
                    <div class="fw-bold font-small-3">This will create an additional job for Sales</div>
                </template>
                <template v-else>
                    <div>Attach to contract</div>
                    <div class="fw-bold font-small-3">If the contract moves to a different unit, this job will move as well.</div>
                </template>
                <el-checkbox-group v-model="contractIds" :min="0" :max="1">
                    <el-checkbox class="my-2 d-block" v-for="(contracts, status) in contractsByStatus"
                                 :label="contracts[0].id"
                                 :key="contracts[0].id">
                        <span class="d-flex align-items-center">
                            <span class="text-info fw-bold font-small-1 me-2">{{ contracts[0].id }}</span>
                            <span class="font-small-3">[{{ status }}]</span>
                        </span>
                    </el-checkbox>
                </el-checkbox-group>
            </section>
            <section class="fill d-flex align-items-end">
                <div class="mb-1 alert-danger text-center" v-if="httpError">{{ httpError.message }}</div>
                <el-button native-type="button"
                           type="primary"
                           class="p-2 mt-3 w-100 font-large-5 fw-bold"
                           :loading="requestInProgress"
                           @click="onValidate()">
                    CREATE
                </el-button>
            </section>
        </el-form>
    </default-layout>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "../layout/DefaultLayout.vue";
import {contractContext, jobContext, productContext, unitContext} from "@/store";
import _ from "lodash";
import ProductGetters from "@/store/modules/product/product-getters";
import ProductData from "@/http/data/product-data";
import Camera from "@/components/devices/Camera.vue";
import PictureCarousel from "@/components/controls/PictureCarousel.vue";
import StockProduct from "@/types/models/stock-product";
import CreateJobRequest from "@/http/requests/create-job-request";
import UnitGetters from "@/store/modules/unit/unit-getters";
import JobState from "@/store/modules/job/job-state";
import ProductState from "@/store/modules/product/product-state";
import ErrorData from "@/http/data/error-data";
import {ElCarousel, ElCarouselItem, ElForm} from "element-plus";
import dayjs from "dayjs";
import ContractData from "@/http/data/contract-data";

@Options({
    name: "NewJobOptions",
    components: {
        DefaultLayout,
        Camera,
        PictureCarousel
    },
})
export default class JobCreate extends Vue {
    private pSelectedProduct: null|StockProduct = null;

    formRef: typeof ElForm|null = null;

    setHttpError = jobContext.mutations.setHttpError;

    createJob = jobContext.actions.createJob;

    cameraImageUploadElement!: HTMLInputElement;
    imageReader!: FileReader;

    formData = {
        description: "",
        inventoryType: "other",
        category: this.productCategories[0],
        productId: null
    } as {
        description: string;
        inventoryType: string;
        category: string;
        productId: number|null;
    };

    taskIsFinished = false;
    blockRoomFrom: null|string = null;
    blockRoomUntil: null|string = null;

    contractIds = [];
    chargeGuest = [];
    materialsAreAvailable = false;
    requiresEmptyRoom = false;
    blockRoom = false;

    requestInProgress = false;
    showCamera = false;

    photoUrls: string[] = [];

    mounted(): void {
        this.cameraImageUploadElement = document.querySelector("#camera-image-upload") as HTMLInputElement;
        this.imageReader = new FileReader();

        this.imageReader.addEventListener("load", () => {
            this.photoUrls.push(this.imageReader.result as string);

            const carouselRef = this.$refs["jobPhotoCarousel"] as typeof ElCarousel;

            if (!carouselRef) return;

            this.$nextTick(() => carouselRef.setActiveItem(this.photoUrls.length - 1));
        });

        this.setHttpError(null);

        this.blockRoomFrom = dayjs().utc(true).toISOString();
    }

    get activeUnit(): UnitGetters["selectedUnit"] {
        return unitContext.getters.selectedUnit;
    }
    
    get activeContract(): UnitGetters["selectedUnitActiveContract"] {
        return unitContext.getters.selectedUnitActiveContract;
    }

    get contractsByStatus(): Record<string, ContractData[]> {
        let contractsByStatus = _.chain(unitContext.getters.selectedUnitContracts)
            .orderBy(c => c.start_date)
            .groupBy(c => this.getContractStatus(c))
            .value();

        if (this.isProductTask) {
            delete contractsByStatus["Next arrivals"];
        }

        return contractsByStatus;
    }

    get displayCameraBox(): boolean {
        return !(this.isInventoryTask && this.formData.inventoryType === "missing");
    }

    get formRules(): Record<string, unknown> {
        const descriptionRules: unknown[] = [];
        const categoryRules: unknown[] = [];
        const productIdRules: unknown[] = [];

        const rules = {
            description: descriptionRules,
            category: categoryRules,
            productId: productIdRules
        };

        if (this.isProductTask) {
            descriptionRules.push({type: "string", required: false});
            productIdRules.push({type: "number", required: true, message: "product is required"});
        } else {
            descriptionRules.push({type: "string", required: true, message: "Description is required"});
            productIdRules.push({type: "number", required: false});
        }

        return rules;
    }

    get httpError(): JobState["httpError"] {
        return jobContext.state.httpError;
    }

    get isBlocked(): boolean {
        return !!this.activeUnit?.blockade;
    }

    get isConciergeTask(): boolean {
        return this.type === "concierge";
    }

    get isHandymanTask(): boolean {
        return this.type === "handyman";
    }

    get isInventoryTask(): boolean {
        return this.isConciergeTask || this.isHandymanTask;
    }

    get isProductTask(): boolean {
        return this.isInventoryTask && this.formData.inventoryType !== "other";
    }

    get productCategories(): string[] {
        return _.keys(this.productsByCategoryName);
    }

    get products(): ProductState["products"] {
        return productContext.state.products;
    }

    get productsByCategoryName(): ProductGetters["productsByCategoryName"] {
        return productContext.getters.productsByCategoryName;
    }

    get productsBelongingToSelectedCategory(): ProductData[] {
        return this.productsByCategoryName[this.formData.category];
    }

    get selectedProduct(): null|StockProduct {
        if (this.pSelectedProduct && this.pSelectedProduct.data.id === this.formData.productId) {
            return this.pSelectedProduct;
        }

        const product = _.find(this.products, (p: ProductData) => p.id === this.formData.productId);

        if (!product) return null;

        this.pSelectedProduct = new StockProduct(product);

        return this.pSelectedProduct;
    }

    get type(): string {
        return jobContext.state.selectedNewJobType;
    }

    clearSelectedProduct(): void {
        this.formData.productId = null;
    }

    disabledDate(time: Date): boolean {
        return dayjs().isAfter(time, "day");
    }

    decreaseProductAmount(product: StockProduct): void {
        product.amount--;

        if (product.amount <= 0) {
            this.clearSelectedProduct();
        }
    }

    deleteCurrentPhoto(): void {
        const carouselRef = this.$refs["jobPhotoCarousel"] as typeof ElCarousel;

        if (!carouselRef) return;

        const carouselItemIndex = _.findIndex(carouselRef.items, (item: typeof ElCarouselItem) => item.active);

        _.pullAt(this.photoUrls, carouselItemIndex);

        carouselRef.prev();
    }

    getContractStatus(contract: ContractData): string {
        return contractContext.getters.getContractStatus(contract);
    }

    getError(key: string): string {
        return (this.httpError as ErrorData).errors[key][0];
    }

    increaseProductAmount(product: StockProduct): void {
        product.amount++;
    }

    isEmpty(obj: Record<string, unknown>): boolean {
        return _.isEmpty(obj);
    }

    onCapturePhoto(photoUrl: string): void {
        this.photoUrls.push(photoUrl);
    }

    onFileUpload(event: Event): void {
        if (!event) return;

        const inputElement = event.target as HTMLInputElement;

        if (!inputElement || !inputElement.files) return;

        for (const file of inputElement.files) {
            this.imageReader.readAsDataURL(file);
        }
    }

    async onSubmit(): Promise<void> {
        if (!this.activeUnit || !this.activeUnit.id) return;

        const jobRequest = new CreateJobRequest(
            this.type,
            this.activeUnit.id,
            this.formData.description
        );

        if (this.contractIds.length > 0) {
            jobRequest.contract_id = this.contractIds[0];
        }

        if (jobContext.state.selectedNewJobUnitCheckTodoResultId) {
            jobRequest.unit_check_todo_result_id = jobContext.state.selectedNewJobUnitCheckTodoResultId;
        }
        
        if (this.selectedProduct && this.selectedProduct.data.id) {
            jobRequest.product = {
                id: this.selectedProduct.data.id,
                amount: this.selectedProduct.amount
            }

            jobRequest.description = _.trimEnd(jobRequest.description, ". ")
                + (jobRequest.description ? ". " : "")
                + (this.formData.inventoryType === "replacement" ? "Vervang " : "Aanvullen ")
                + this.selectedProduct.amount + " x ["
                + this.selectedProduct.data.name + "].";
        }
        
        if (this.photoUrls.length > 0) {
            jobRequest.images = this.photoUrls;
        }
        
        switch (this.type) {
        case "concierge":
            jobRequest.inventory_type = this.formData.inventoryType;
            break;
        case "handyman":
            jobRequest.inventory_type = this.formData.inventoryType;
            jobRequest.materials_are_available = this.materialsAreAvailable;
            jobRequest.requires_empty_room = this.requiresEmptyRoom;

            if (this.blockRoom) {
                jobRequest.block_room_from = dayjs(this.blockRoomFrom as string).utc(true).toISOString();
                jobRequest.block_room_until = dayjs(this.blockRoomUntil as string).utc(true).toISOString();
            }

            break;
        }

        this.requestInProgress = true;

        try {
            await this.createJob(jobRequest);
        } catch (e) {
            console.error(e);
        }
        finally {
            this.requestInProgress = false;
        }

        if (!this.httpError) {
            await this.$router.go(-2);
        }
    }

    onValidate(): void {
        if (!this.formRef) return;

        this.formRef.validate((isValid: boolean) => {
            if (!isValid) return;

            this.onSubmit();
        });
    }

    openFileDialog(): void {
        this.cameraImageUploadElement.click();
    }

}
</script>

<style lang="scss">
#create-job-component {
    .el-carousel, .el-carousel__container {
        height: 100%;
    }

    .el-radio-group, .el-radio-button, .el-radio-button__inner {
        width: 100%;
    }

    .el-radio-group {
        display: flex;
    }

    .hide-arrows {
        .el-carousel__arrow {
            display: none;
        }
    }
}
</style>

<style lang="scss" scoped>
@import "src/scss/_variables";

.bb-product {
    &:not(:last-child) {
        border-bottom: solid 1px $blue-darken-2;
    }
}

.camera {
    width: 100%;
    height: calc((100vw - 2rem) * (9 / 16));
    max-height: calc(#{$xs} * (9 / 16));
    border: $grey-lighten-2 3px dashed;
}

.camera-btn {
    font-size: 1.5em;
    width: 60px;
    height: 60px;
}

.camera-btn-light {
    font-size: 1em;
    width: 40px;
    height: 40px;
    background-color: rgba(31, 45, 61, .23);
    border: none;
}

.elevate {
    z-index: 10;
}

.text-grey-lighten-1 {
  color: $grey-lighten-1;
}

.text-info-dark {
    color: $blue-darken-2;
}

.text-info-darker {
    color: $blue-darken-4;
}

.w-min-80px {
    min-width: 80px;
}
</style>