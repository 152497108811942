
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";

class Props {
    imageUrls = prop({
        type: Array,
        required: false,
        default: []
    })
}

@Options({
    name: "PictureCarousel"
})
export default class PictureCarousel extends Vue.with(Props) {
    declare imageUrls: string[];
}
