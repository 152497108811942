export default class CreateJobRequest {
    type: string;
    unit_id: string;
    description: string;

    /** Optional */
    contract_id: undefined|string;
    unit_check_todo_result_id: undefined|number;

    /** Mandatory if type === 'inventory' */
    inventory_type: undefined|string;

    /** Never mandatory */
    product: undefined|{
        id: number;
        amount: number;
    };

    /** Never mandatory */
    images: undefined|string[];

    /** Mandatory if type is handyman */
    materials_are_available: undefined|boolean;
    requires_empty_room: undefined|boolean;

    /** Optional if type is handyman */
    block_room_from: undefined|string;
    block_room_until: undefined|string;

    constructor(type: string, unit_id: string, description: string) {
        this.type = type;
        this.unit_id = unit_id;
        this.description = description;
    }
}