import ProductData from "@/http/data/product-data";

export default class StockProduct {
    data: ProductData;
    amount: number;


    constructor(product: ProductData) {
        this.data = product;
        this.amount = 1;
    }
}