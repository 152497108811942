<template>
    <div>
        <main id="camera-container" class="bg-dark size-constraint">
            <video id="camera" class="video" autoplay />
        </main>
        <footer class="p-2 fixed-bottom d-flex align-items-center bg-secondary size-constraint">
            <div class="row w-100">
                <div class="col-4 d-flex justify-content-center">
                    <button name="take-photo" class="camera-btn btn rounded-circle text-white" @click="onStop()">
                        <i class="fa fa-stop" />
                    </button>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <button name="take-photo"
                            class="camera-btn btn rounded-circle text-white"
                            @click="onTakePhoto()"
                            :disabled="takePhotoButtonIsDisabled">
                        <i class="fa fa-camera" />
                    </button>
                </div>
                <div class="col-4 d-flex justify-content-center">
                    <button name="switch-cam"
                            class="camera-btn btn rounded-circle text-white"
                            @click="onSwitchCameraMode()"
                    >
                        <i class="fa fa-exchange-alt" />
                    </button>
                </div>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {CameraMode} from "@/types/enums";
import _ from "lodash";

@Options({
    name: "Camera"
})
export default class JobCreate extends Vue {
    canvas!: HTMLCanvasElement;
    video!: HTMLVideoElement;
    devices!: MediaDeviceInfo[];

    currentDevice = "Unknown";

    mode = CameraMode.User;

    switchCameraButtonIsDisabled = true;
    takePhotoButtonIsDisabled = true;

    mounted(): void {
        this.canvas = document.createElement("canvas");

        this.video = document.querySelector("#camera") as HTMLVideoElement;

        this.mode = Math.random() > 0.5 ? CameraMode.User : CameraMode.Environment;

        this.detectCameras().then(() => {
            if (this.devices.length > 2) {
                this.switchCameraButtonIsDisabled = false;
            } else if (this.devices.length === 0) {
                return;
            }

            this.takePhotoButtonIsDisabled = false;

            this.initCamera();
        });
    }

    applyVideoFeed(stream: MediaStream): void {
        this.video.srcObject = stream;

        this.currentDevice = stream.getVideoTracks()[0].label;
    }

    async detectCameras(): Promise<void> {
        return navigator.mediaDevices.enumerateDevices()
            .then(this.setDevices);
    }

    getMedia(): Promise<MediaStream> {
        const constraints = {
            audio: false,
            video: {
                facingMode: this.mode
            }
        }

        return navigator.mediaDevices.getUserMedia(constraints);
    }

    initCamera(): void {
        this.getMedia()
            .then(this.applyVideoFeed)
            .catch(this.onInitError);
    }

    onInitError(e: Error): void {
        console.error(e);
    }

    onStop(): void {
        this.$emit("stop-camera");
    }

    onSwitchCameraMode(): void {
        this.mode = this.mode === CameraMode.User ? CameraMode.Environment : CameraMode.User;

        this.stopMediaTracks();
        this.initCamera();
    }

    onTakePhoto(): void {
        this.canvas.width = this.video.videoWidth;
        this.canvas.height = this.video.videoHeight;

        const context = this.canvas.getContext("2d") as CanvasRenderingContext2D;

        context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

        const jpeg = this.canvas.toDataURL("image/jpeg");

        this.video.classList.add("snapshot");

        setTimeout(() => this.video.classList.remove("snapshot"), 1000);

        this.$emit("capture-photo", jpeg);
    }

    setDevices(devices: MediaDeviceInfo[]): void {
        this.devices = _.filter(devices, (device: MediaDeviceInfo) => device.kind === "videoinput");
    }

    stopMediaTracks(): void {
        if (this.video.srcObject instanceof MediaStream) {
            for (const track of this.video.srcObject.getVideoTracks()) {
                track.stop();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

@keyframes brightDarkBright {
    0%   {
        filter: brightness(1);
        -webkit-filter: brightness(1);
    }
    50%  {
        filter: brightness(0);
        -webkit-filter: brightness(0);
    }
    100% {
        filter: brightness(1);
        -webkit-filter: brightness(1);
    }
}

$footer-height: 80px;

.camera-btn {
    width: 50px;
    height: 50px;
    border: solid 1px $light;
}

.snapshot {
    -webkit-animation: brightDarkBright 1s;
    -moz-animation:    brightDarkBright 1s;
    -o-animation:      brightDarkBright 1s;
    animation:         brightDarkBright 1s;
}

footer {
    height: $footer-height;
}

video {
    width: 100%;
    height: calc(100vh - #{$footer-height});
    background-color: black;
}
</style>