
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import DefaultLayout from "../layout/DefaultLayout.vue";
import {contractContext, jobContext, productContext, unitContext} from "@/store";
import _ from "lodash";
import ProductGetters from "@/store/modules/product/product-getters";
import ProductData from "@/http/data/product-data";
import Camera from "@/components/devices/Camera.vue";
import PictureCarousel from "@/components/controls/PictureCarousel.vue";
import StockProduct from "@/types/models/stock-product";
import CreateJobRequest from "@/http/requests/create-job-request";
import UnitGetters from "@/store/modules/unit/unit-getters";
import JobState from "@/store/modules/job/job-state";
import ProductState from "@/store/modules/product/product-state";
import ErrorData from "@/http/data/error-data";
import {ElCarousel, ElCarouselItem, ElForm} from "element-plus";
import dayjs from "dayjs";
import ContractData from "@/http/data/contract-data";

@Options({
    name: "NewJobOptions",
    components: {
        DefaultLayout,
        Camera,
        PictureCarousel
    },
})
export default class JobCreate extends Vue {
    private pSelectedProduct: null|StockProduct = null;

    formRef: typeof ElForm|null = null;

    setHttpError = jobContext.mutations.setHttpError;

    createJob = jobContext.actions.createJob;

    cameraImageUploadElement!: HTMLInputElement;
    imageReader!: FileReader;

    formData = {
        description: "",
        inventoryType: "other",
        category: this.productCategories[0],
        productId: null
    } as {
        description: string;
        inventoryType: string;
        category: string;
        productId: number|null;
    };

    taskIsFinished = false;
    blockRoomFrom: null|string = null;
    blockRoomUntil: null|string = null;

    contractIds = [];
    chargeGuest = [];
    materialsAreAvailable = false;
    requiresEmptyRoom = false;
    blockRoom = false;

    requestInProgress = false;
    showCamera = false;

    photoUrls: string[] = [];

    mounted(): void {
        this.cameraImageUploadElement = document.querySelector("#camera-image-upload") as HTMLInputElement;
        this.imageReader = new FileReader();

        this.imageReader.addEventListener("load", () => {
            this.photoUrls.push(this.imageReader.result as string);

            const carouselRef = this.$refs["jobPhotoCarousel"] as typeof ElCarousel;

            if (!carouselRef) return;

            this.$nextTick(() => carouselRef.setActiveItem(this.photoUrls.length - 1));
        });

        this.setHttpError(null);

        this.blockRoomFrom = dayjs().utc(true).toISOString();
    }

    get activeUnit(): UnitGetters["selectedUnit"] {
        return unitContext.getters.selectedUnit;
    }
    
    get activeContract(): UnitGetters["selectedUnitActiveContract"] {
        return unitContext.getters.selectedUnitActiveContract;
    }

    get contractsByStatus(): Record<string, ContractData[]> {
        let contractsByStatus = _.chain(unitContext.getters.selectedUnitContracts)
            .orderBy(c => c.start_date)
            .groupBy(c => this.getContractStatus(c))
            .value();

        if (this.isProductTask) {
            delete contractsByStatus["Next arrivals"];
        }

        return contractsByStatus;
    }

    get displayCameraBox(): boolean {
        return !(this.isInventoryTask && this.formData.inventoryType === "missing");
    }

    get formRules(): Record<string, unknown> {
        const descriptionRules: unknown[] = [];
        const categoryRules: unknown[] = [];
        const productIdRules: unknown[] = [];

        const rules = {
            description: descriptionRules,
            category: categoryRules,
            productId: productIdRules
        };

        if (this.isProductTask) {
            descriptionRules.push({type: "string", required: false});
            productIdRules.push({type: "number", required: true, message: "product is required"});
        } else {
            descriptionRules.push({type: "string", required: true, message: "Description is required"});
            productIdRules.push({type: "number", required: false});
        }

        return rules;
    }

    get httpError(): JobState["httpError"] {
        return jobContext.state.httpError;
    }

    get isBlocked(): boolean {
        return !!this.activeUnit?.blockade;
    }

    get isConciergeTask(): boolean {
        return this.type === "concierge";
    }

    get isHandymanTask(): boolean {
        return this.type === "handyman";
    }

    get isInventoryTask(): boolean {
        return this.isConciergeTask || this.isHandymanTask;
    }

    get isProductTask(): boolean {
        return this.isInventoryTask && this.formData.inventoryType !== "other";
    }

    get productCategories(): string[] {
        return _.keys(this.productsByCategoryName);
    }

    get products(): ProductState["products"] {
        return productContext.state.products;
    }

    get productsByCategoryName(): ProductGetters["productsByCategoryName"] {
        return productContext.getters.productsByCategoryName;
    }

    get productsBelongingToSelectedCategory(): ProductData[] {
        return this.productsByCategoryName[this.formData.category];
    }

    get selectedProduct(): null|StockProduct {
        if (this.pSelectedProduct && this.pSelectedProduct.data.id === this.formData.productId) {
            return this.pSelectedProduct;
        }

        const product = _.find(this.products, (p: ProductData) => p.id === this.formData.productId);

        if (!product) return null;

        this.pSelectedProduct = new StockProduct(product);

        return this.pSelectedProduct;
    }

    get type(): string {
        return jobContext.state.selectedNewJobType;
    }

    clearSelectedProduct(): void {
        this.formData.productId = null;
    }

    disabledDate(time: Date): boolean {
        return dayjs().isAfter(time, "day");
    }

    decreaseProductAmount(product: StockProduct): void {
        product.amount--;

        if (product.amount <= 0) {
            this.clearSelectedProduct();
        }
    }

    deleteCurrentPhoto(): void {
        const carouselRef = this.$refs["jobPhotoCarousel"] as typeof ElCarousel;

        if (!carouselRef) return;

        const carouselItemIndex = _.findIndex(carouselRef.items, (item: typeof ElCarouselItem) => item.active);

        _.pullAt(this.photoUrls, carouselItemIndex);

        carouselRef.prev();
    }

    getContractStatus(contract: ContractData): string {
        return contractContext.getters.getContractStatus(contract);
    }

    getError(key: string): string {
        return (this.httpError as ErrorData).errors[key][0];
    }

    increaseProductAmount(product: StockProduct): void {
        product.amount++;
    }

    isEmpty(obj: Record<string, unknown>): boolean {
        return _.isEmpty(obj);
    }

    onCapturePhoto(photoUrl: string): void {
        this.photoUrls.push(photoUrl);
    }

    onFileUpload(event: Event): void {
        if (!event) return;

        const inputElement = event.target as HTMLInputElement;

        if (!inputElement || !inputElement.files) return;

        for (const file of inputElement.files) {
            this.imageReader.readAsDataURL(file);
        }
    }

    async onSubmit(): Promise<void> {
        if (!this.activeUnit || !this.activeUnit.id) return;

        const jobRequest = new CreateJobRequest(
            this.type,
            this.activeUnit.id,
            this.formData.description
        );

        if (this.contractIds.length > 0) {
            jobRequest.contract_id = this.contractIds[0];
        }

        if (jobContext.state.selectedNewJobUnitCheckTodoResultId) {
            jobRequest.unit_check_todo_result_id = jobContext.state.selectedNewJobUnitCheckTodoResultId;
        }
        
        if (this.selectedProduct && this.selectedProduct.data.id) {
            jobRequest.product = {
                id: this.selectedProduct.data.id,
                amount: this.selectedProduct.amount
            }

            jobRequest.description = _.trimEnd(jobRequest.description, ". ")
                + (jobRequest.description ? ". " : "")
                + (this.formData.inventoryType === "replacement" ? "Vervang " : "Aanvullen ")
                + this.selectedProduct.amount + " x ["
                + this.selectedProduct.data.name + "].";
        }
        
        if (this.photoUrls.length > 0) {
            jobRequest.images = this.photoUrls;
        }
        
        switch (this.type) {
        case "concierge":
            jobRequest.inventory_type = this.formData.inventoryType;
            break;
        case "handyman":
            jobRequest.inventory_type = this.formData.inventoryType;
            jobRequest.materials_are_available = this.materialsAreAvailable;
            jobRequest.requires_empty_room = this.requiresEmptyRoom;

            if (this.blockRoom) {
                jobRequest.block_room_from = dayjs(this.blockRoomFrom as string).utc(true).toISOString();
                jobRequest.block_room_until = dayjs(this.blockRoomUntil as string).utc(true).toISOString();
            }

            break;
        }

        this.requestInProgress = true;

        try {
            await this.createJob(jobRequest);
        } catch (e) {
            console.error(e);
        }
        finally {
            this.requestInProgress = false;
        }

        if (!this.httpError) {
            await this.$router.go(-2);
        }
    }

    onValidate(): void {
        if (!this.formRef) return;

        this.formRef.validate((isValid: boolean) => {
            if (!isValid) return;

            this.onSubmit();
        });
    }

    openFileDialog(): void {
        this.cameraImageUploadElement.click();
    }

}
