<template>
    <div :id="`carousel-${uuid}`" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item"
                 v-for="(imageUrl, i) in imageUrls"
                 :class="[`item-${uuid}`, {active: i === 0}]"
                 :key="i">
                <img class="d-block w-100" :src="imageUrl" :alt="`img-${i}`">
            </div>
        </div>
        <a class="carousel-control-prev" :href="`#carousel-${uuid}`" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" :href="`#carousel-${uuid}`" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
</template>

<script lang="ts">
import Vue from "@/extensions/Vue";
import {Options, prop} from "vue-class-component";

class Props {
    imageUrls = prop({
        type: Array,
        required: false,
        default: []
    })
}

@Options({
    name: "PictureCarousel"
})
export default class PictureCarousel extends Vue.with(Props) {
    declare imageUrls: string[];
}
</script>

<style scoped>

</style>