
import Vue from "@/extensions/Vue";
import {Options} from "vue-class-component";
import {CameraMode} from "@/types/enums";
import _ from "lodash";

@Options({
    name: "Camera"
})
export default class JobCreate extends Vue {
    canvas!: HTMLCanvasElement;
    video!: HTMLVideoElement;
    devices!: MediaDeviceInfo[];

    currentDevice = "Unknown";

    mode = CameraMode.User;

    switchCameraButtonIsDisabled = true;
    takePhotoButtonIsDisabled = true;

    mounted(): void {
        this.canvas = document.createElement("canvas");

        this.video = document.querySelector("#camera") as HTMLVideoElement;

        this.mode = Math.random() > 0.5 ? CameraMode.User : CameraMode.Environment;

        this.detectCameras().then(() => {
            if (this.devices.length > 2) {
                this.switchCameraButtonIsDisabled = false;
            } else if (this.devices.length === 0) {
                return;
            }

            this.takePhotoButtonIsDisabled = false;

            this.initCamera();
        });
    }

    applyVideoFeed(stream: MediaStream): void {
        this.video.srcObject = stream;

        this.currentDevice = stream.getVideoTracks()[0].label;
    }

    async detectCameras(): Promise<void> {
        return navigator.mediaDevices.enumerateDevices()
            .then(this.setDevices);
    }

    getMedia(): Promise<MediaStream> {
        const constraints = {
            audio: false,
            video: {
                facingMode: this.mode
            }
        }

        return navigator.mediaDevices.getUserMedia(constraints);
    }

    initCamera(): void {
        this.getMedia()
            .then(this.applyVideoFeed)
            .catch(this.onInitError);
    }

    onInitError(e: Error): void {
        console.error(e);
    }

    onStop(): void {
        this.$emit("stop-camera");
    }

    onSwitchCameraMode(): void {
        this.mode = this.mode === CameraMode.User ? CameraMode.Environment : CameraMode.User;

        this.stopMediaTracks();
        this.initCamera();
    }

    onTakePhoto(): void {
        this.canvas.width = this.video.videoWidth;
        this.canvas.height = this.video.videoHeight;

        const context = this.canvas.getContext("2d") as CanvasRenderingContext2D;

        context.drawImage(this.video, 0, 0, this.canvas.width, this.canvas.height);

        const jpeg = this.canvas.toDataURL("image/jpeg");

        this.video.classList.add("snapshot");

        setTimeout(() => this.video.classList.remove("snapshot"), 1000);

        this.$emit("capture-photo", jpeg);
    }

    setDevices(devices: MediaDeviceInfo[]): void {
        this.devices = _.filter(devices, (device: MediaDeviceInfo) => device.kind === "videoinput");
    }

    stopMediaTracks(): void {
        if (this.video.srcObject instanceof MediaStream) {
            for (const track of this.video.srcObject.getVideoTracks()) {
                track.stop();
            }
        }
    }
}
